import { createSlice } from '@reduxjs/toolkit';

const senderDetails = {
  userType: '',
  SenderName: '',
  CompanyName: '',
  Phone: '',
  Email: '',
  PhoneList: [{}],
  EmailList: [{}],
  ContactList: [],
  Fax: ''
};

const senderAddress = {
  AddressLine1: '',
  AddressLine2: '',
  AddressLine3: '',
  // TownCity: '',
  State: '',
  City: '',
  Country: null,
  PostCode: '',
  IsDefault: false,

  AddressList: [],
};

const parcelDetails = {
  Type: {},
  NumberOfParcel: '1',
  IMODetails: [{ IMONumber: '', VesselName: '', Active: undefined, NotFound: false, Icon: '' }],
  ParcelTypes: []
};

const pickUpDateTime = {
  EarliestDateTime: '',
  LatestDateTime: '',
  BunkerPort: '',
};

const imoDetails = {
  IMO: '',
  VesselName: '',
  Active: true,
  ContactList: [
    {
      ContactEmail: '',
      ContactType: ''
    }
  ]
};
const requestCompletionDocument = {
  referenceNo: '',
  document: [
    { type: 'pdf', documentName: '', file: '#' },
    { type: 'pdf', documentName: 'Commercial Invoice', file: '#' },
    { type: 'pdf', documentName: 'MSDS', file: '#' },
  ],
};

const orderHistory = [
  { OrderDetails: true, Client: 'HK', ReferenceNo: '1234986', AWBNO: '12349874', OrderStatus: 'In Transit', StatusDate: '12/11/2021', },
  { OrderDetails: true, Client: 'HK', ReferenceNo: '1234987', AWBNO: '12349878', OrderStatus: 'Pending Pickup', StatusDate: '14/11/2021', },
];

const initialState = {
  userSenderDetails: senderDetails,
  userSenderAddress: senderAddress,
  userParcelDetails: parcelDetails,
  userPickUpDateTime: pickUpDateTime,
  userRequestCompletion: requestCompletionDocument,
  userOrderHistory: orderHistory,
  isImoValid: true,
  isLoading: false,
  userImoDetails: imoDetails.ContactList,
  isImoInactive: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset(state) {
      const slice = { ...state };
      slice.userParcelDetails = initialState.userParcelDetails;
      return slice;
    },
    resetGuest(state) {
      const slice = { ...state };
      slice.userParcelDetails = initialState.userParcelDetails;
      slice.userSenderAddress = initialState.userSenderAddress;
      slice.userSenderDetails = initialState.userSenderDetails;
      return slice;
    },
    resetCompletion(state) {
      const slice = { ...state };
      slice.userParcelDetails = initialState.userParcelDetails;
      slice.userPickUpDateTime = initialState.userPickUpDateTime;
      return slice;
    },
    getUserType(state, action) {
      const { type, userDetails, userAddresses } = action.payload;
      if (type === 'guest') {
        state.userSenderDetails = { userType: type, ...state.userSenderDetails };
      } else {
        state.userSenderDetails = { userType: type, ...userDetails };
        state.userSenderAddress.AddressList = userAddresses;
      }
    },
    resetUserType(state) {
      state.userSenderDetails = { userType: state.userSenderDetails.userType,
        SenderName: '',
        CompanyName: '',
        Phone: '',
        Email: '',
        PhoneList: [{}],
        EmailList: [{}],
        ContactList: [], };
      state.userSenderAddress.AddressList = [];
      state.userImoDetails = {
        IMO: '',
        VesselName: '',
        Active: true,
        ContactList: []
      };
      state.userPickUpDateTime = {
        EarliestDateTime: '',
        LatestDateTime: '',
        BunkerPort: '',
      };
      state.userParcelDetails = {
        Type: {},
        NumberOfParcel: '1',
        IMODetails: [],
        ParcelTypes: []
      };
    },

    getUserPickupDcuments(state) {
      const awbRandomNumber = Math.floor(Math.random() * 80000000);
      const referenceRandomNumber = Math.floor(Math.random() * 80000000);
      state.userRequestCompletion.document[0].documentName = awbRandomNumber;
      state.userRequestCompletion.referenceNo = referenceRandomNumber;
    },

    updateUserSenderDetails(state, action) {
      state.userSenderDetails[action.payload.propName] = action.payload.propValue;
    },

    updateUserPhoneEamilDetails(state, action) {
      const { propName, propValue, target, index } = action.payload;
      state.userSenderDetails[target][index][propName] = propValue;
    },

    updatePhoneEmailDetails(state) {
      // const emailList = state.userSenderDetails.EmailList;
      // const phoneList = state.userSenderDetails.PhoneList;
      const contactList = state.userSenderDetails.ContactList;
      let emailId = '';
      let phoneNumber = '';

      for (let i = 0; i < contactList.length; i++) {
        if (contactList[i].Phone !== '' && contactList[i].IsDefault === true) {
          phoneNumber = contactList[i].Phone;
        }
        if (contactList[i].Email !== '' && contactList[i].IsDefault === true) {
          emailId = contactList[i].Email;
        }
      }
      /*  for (let i = 0; i < emailList.length; i++) {
        if (emailList[i].Active) emailId = emailList[i].id;
      }

      for (let i = 0; i < phoneList.length; i++) {
        if (phoneList[i].Active) phoneNumber = phoneList[i].number;
      } */

      state.userSenderDetails.Phone = phoneNumber;
      state.userSenderDetails.Email = emailId;
    },

    updateActivePhoneNumber(state, action) {
      const { index } = action.payload;
      const prevActive = state.userSenderDetails.ContactList.indexOf(state.userSenderDetails.ContactList.find((phoneData) => phoneData.IsDefault === true && phoneData.Phone !== ''));
      state.userSenderDetails.ContactList[prevActive].IsDefault = false;
      state.userSenderDetails.ContactList[index].IsDefault = true;
    },

    updateActiveEamilId(state, action) {
      const { index } = action.payload;
      const prevActive = state.userSenderDetails.ContactList.indexOf(state.userSenderDetails.ContactList.find((emailData) => emailData.IsDefault === true && emailData.Email !== ''));
      state.userSenderDetails.ContactList[prevActive].IsDefault = false;
      state.userSenderDetails.ContactList[index].IsDefault = true;
    },

    updateUserSenderAddress(state, action) {
      state.userSenderAddress[action.payload.propName] = action.payload.propValue;
    },

    updateSenderAddress(state) {
      const addressList = state.userSenderAddress.AddressList;
      let firstLine = state.userSenderAddress.AddressLine1;
      let secondLine = state.userSenderAddress.AddressLine2;
      let thirdLine = state.userSenderAddress.AddressLine3;
      // let townCity = state.userSenderAddress.TownCity;
      let addressState = state.userSenderAddress.State;
      let postCode = state.userSenderAddress.PostCode;
      let city = state.userSenderAddress.City;
      let country = state.userSenderAddress.Country;
      let isDefault = state.userSenderAddress.IsDefault;

      for (let i = 0; i < addressList.length; i++) {
        if (addressList[i].Active) {
          firstLine = addressList[i].AddressLine1;
          secondLine = addressList[i].AddressLine2;
          thirdLine = addressList[i].AddressLine3;
          // townCity = addressList[i].TownCity;
          addressState = addressList[i].State;
          postCode = addressList[i].PostCode;
          city = addressList[i].City;
          country = { id: addressList[i].Country, text: addressList[i].CountryName };
          isDefault = addressList[i].IsDefault;
        }
        if (addressList[i].IsDefault === true) {
          state.userSenderAddress.AddressLine1 = firstLine;
          state.userSenderAddress.AddressLine2 = secondLine;
          state.userSenderAddress.AddressLine3 = thirdLine;
          // state.userSenderAddress.TownCity = townCity;
          state.userSenderAddress.State = addressState;
          state.userSenderAddress.PostCode = postCode;
          state.userSenderAddress.City = city;
          state.userSenderAddress.Country = country;
          state.userSenderAddress.IsDefault = isDefault;
        }
      }
    },

    updateActiveAddress(state, action) {
      const { index } = action.payload;
      const prevActive = state.userSenderAddress.AddressList.indexOf(state.userSenderAddress.AddressList.find((addressData) => addressData.IsDefault === true));
      state.userSenderAddress.AddressList[prevActive].IsDefault = false;
      state.userSenderAddress.AddressList[index].IsDefault = true;
    },

    updateUserParcelDetails(state, action) {
      state.userParcelDetails[action.payload.propName] = action.payload.propValue;
    },

    updateUserParcelDetailsType(state, action) {
      state.userParcelDetails.Type[action.payload.propName] = action.payload.propValue;
      // eslint-disable-next-line array-callback-return
      state.userParcelDetails.ParcelTypes.map((val) => {
        if (val.Text === action.payload.propName) {
          val['Checked'] = action.payload.propValue;
        }
      });
    },
    updateUserRequiredParcelDetailsType(state, action) {
      state.userParcelDetails[action.payload.propName] = action.payload.propValue;
    },
    updateParcelDetailsType(state, action) {
      state.userParcelDetails.Type = action.payload;
    },
    updateUserParcelDetailsIMODetails(state, action) {
      const { propName, propValue, index } = action.payload;
      state.userParcelDetails.IMODetails[index][propName] = propValue;
      if (propName === 'Active') {
        if (propValue === false) {
          state.userParcelDetails.IMODetails[index]['Icon'] = 'Inactive';
        } else {
          state.userParcelDetails.IMODetails[index]['Icon'] = 'Active';
        }
      }
      if (propName === 'NotFound') {
        if (propValue === true) {
          state.userParcelDetails.IMODetails[index]['Icon'] = 'NotFound';
        }
      }
    },

    updateUserPickUpDateTime(state, action) {
      state.userPickUpDateTime[action.payload.propName] = action.payload.propValue;
    },

    addMoreIMODetails(state, action) {
      state.userParcelDetails.IMODetails.push(action.payload);
    },

    removeIMODetails(state, action) {
      const { index } = action.payload;
      state.userParcelDetails.IMODetails.splice(index, 1);
    },

    addNewOrderHistory(state, action) {
      const { refNo, awbNo } = action.payload;
      state.userOrderHistory.push({
        ...state.userOrderHistory[0],
        OrderDetails: false,
        Client: '',
        ReferenceNo: refNo,
        AWBNO: awbNo,
        OrderStatus: 'Delivered',
        StatusDate: '15/11/2021',
      });
    },
    checkImoValidation(state, action) {
      state.isImoValid = action.payload;
    },
    checkImoInactive(state, action) {
      state.isImoInactive = action.payload;
    },
    changeLoadingState(state, action) {
      state.isLoading = action.payload;
    },
    getImoDetails(state, action) {
      state.userImoDetails = action.payload.ContactList;
    }
  },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
