import {
  // Box,
  Button,
  // Card,
  Grid,
  TextField,
  // Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { orderTrackingActions } from '../../../OrderTracking/store/slices/orderTrakingSlice';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { alpha, styled } from '@mui/material/styles';

const textColor = alpha('#f4f5f7', 0.8);

const CustomTextField = styled(TextField)({
  '& input': {
    color: textColor,
  },
  '& label.Mui-focused': {
    color: textColor,
  },
  // "& label.Mui-error": {
  //   color: "#CF6679",
  // },
  '& .MuiInput-underline:after': {
    borderBottomColor: textColor,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: textColor,
    },

    // "& fieldset.Mui-error": {
    //   borderColor: "#CF6679!important",
    // },
    '&:hover fieldset': {
      borderColor: '#6b778c',
    },
    '&.Mui-focused fieldset': {
      borderColor: textColor,
    },
  },
});
export default function OrderTrack() {
  // const { ...other } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.orderTracking.userOrderTracking);

  // Dispatch Order Traking values to update the fields value in the store
  const dispatchOrderTrackingValuesChanged = useCallback(
    (propName, propValue) => {
      dispatch(
        orderTrackingActions.updateOrderTrackingValue({
          propName,
          propValue,
        })
      );
    },
    [dispatch]
  );

  // Text Value Changed Function
  const onTextChangedHandler = useCallback(
    (event) => {
      const { name, value } = event.target;
      dispatchOrderTrackingValuesChanged(name, value);
    },
    [dispatchOrderTrackingValuesChanged]
  );
  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={8}>
        <CustomTextField
          placeholder="Enter your Tracking Number"
          fullWidth
          variant="outlined"
          size="small"
          name="id"
          value={id}
          onChange={onTextChangedHandler}
          required
          type="number"
          sx={{ backgoundColor: 'whitesmoke' }}
        />
      </Grid>
      <Grid item xs={4}>
        <Button color="primary" size="medium" variant="contained">
          {t('Btn_Track')}
        </Button>
      </Grid>
    </Grid>
  );
}
