import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isReviewVisited: false,
};

const pickUpRequestSlice = createSlice({
  name: 'pickUpRequest',
  initialState,
  reducers: {
    isReviewVisited(state, action) {
      state.isReviewVisited = action.payload;
    }
  }
});

export const pickUpRequestActions = pickUpRequestSlice.actions;
export default pickUpRequestSlice.reducer;
