import apiConfig from '../apiConfig';
import { getAxiosInstance } from '../../utils/axiosApi';
import axios from 'axios';

const axiosInstance = getAxiosInstance({
  baseUrl: apiConfig.IAMUrl,
  addToken: false,
});

const login = async ({ username, password }) => {
  if (!username || !password) {
    throw new Error('Please check your username and password');
  }

  try {
    const response = await axiosInstance.post('/Auth/Authenticate', {
      ApplicationID: apiConfig.ApplicationID,
      Username: username,
      Password: password,
      Provider: 'default',
    });

    const { AccessToken, UserObjects, User, Tenants, Permissions, CustomPermissions } = response.data;

    return {
      accessToken: AccessToken,
      userData: {
        User,
        AccessToken,
        UserObjects,
        Tenants,
        Permissions,
        CustomPermissions
      },
    };
  } catch (e) {
    throw new Error('Please check your username and password');
  }
};

const me = async (token) => {
  try {
    const res = await axiosInstance.post('/Auth/GetUserDataByToken', {
      Token: token,
    });

    const { UserObjects, User, Tenants, Permissions, CustomPermissions } = res.data;

    return {
      User,
      UserObjects,
      Tenants,
      Permissions,
      CustomPermissions,
    };
  } catch (error) {
    throw new Error('GetUserDataByToken error');
  }
};

const changeTenant = async ({ token, tenantId }) => {
  try {
    const res = await axiosInstance.post('/Auth/ChangeUserTenant', {
      Token: token,
      TenantId: tenantId,
    });

    return {
      accessToken: res.data.AccessToken,
    };
  } catch (error) {
    throw new Error('GetUserDataByToken error');
  }
};

const forgotPassword = async ({ username }) => {
  if (!username) {
    throw new Error('Please check your username');
  }

  try {
    const response = await axiosInstance.post('/Auth/ForgotPassword', {
      ApplicationID: apiConfig.ApplicationID,
      Username: username,
      Provider: 'default',
    });

    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return false;
};

const AuthApi = {
  login,
  me,
  changeTenant,
  forgotPassword
};

export default AuthApi;
