import { createSlice } from '@reduxjs/toolkit';
import { parseJwt } from '../../utils/jwt';

const extractUserObjects = (userObjects) => {
  const userObjs = {
    assignedDivisions: [],
    assignedClients: [],
    assignedLocations: [],
    assignedSourceSystems: [],
    assignedProjects: [],
  };

  if (!Array.isArray(userObjects) || userObjects.length === 0) {
    return userObjs;
  }

  const getConfigData = (objectType) => {
    const configData = userObjects.find((x) => x.ObjectType === objectType);
    if (configData && configData.ObjectData) {
      return JSON.parse(configData.ObjectData);
    }

    return null;
  };

  userObjs.assignedDivisions = getConfigData('AssignedDivisions') || [];
  userObjs.assignedClients = getConfigData('AssignedClients') || [];
  userObjs.assignedLocations = getConfigData('AssignedLocations') || [];
  userObjs.assignedSourceSystems = getConfigData('AssignedSourceSystems') || [];
  userObjs.assignedProjects = getConfigData('AssignedProjects') || [];
  return userObjs;
};

const parseRoleClaims = (roleClaims) => {
  const emptySet = {};
  try {
    if (!Array.isArray(roleClaims) || roleClaims.length === 0) {
      return emptySet;
    }

    const permissionClaim = roleClaims.find((c) => c.ClaimName === 'Permission');
    if (!permissionClaim) {
      return emptySet;
    }

    const permissionCodes = JSON.parse(permissionClaim.ClaimValue) || [];
    return permissionCodes.reduce((previousValue, currentValue) => {
      if (currentValue) {
        previousValue[currentValue.toLowerCase()] = true;
      }

      return previousValue;
    }, {});
  } catch (error) {
    console.log(error);
  }

  return emptySet;
};

const transformUserFunctionality = (userFunctionalities) => {
  const emptySet = {};
  try {
    if (!Array.isArray(userFunctionalities) || userFunctionalities.length === 0) {
      return emptySet;
    }

    return userFunctionalities.reduce((prev, { Code, Options }) => {
      if (prev) {
        prev[Code.toLowerCase()] = Options;
      }

      return prev;
    }, {});
  } catch (error) {
    console.log(error);
  }

  return emptySet;
};

const initialState = {
  isAuthenticated: false,
  loginUser: null,
  impersonatedUser: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // updateAuthData(state, action) {
    //   Object.keys(initialState).forEach((key) => {
    //     state[key] = action.payload[key];
    //   });
    // },

    updateLoginUser(state, action) {
      const {
        isAuthenticated,
        user,
        token,
        userObjects,
        dateFormat,
        dateTimeFormat,
        tenantList,
        permissions,
        customPermissions
      } = action.payload;

      const {
        assignedDivisions,
        assignedClients,
        assignedLocations,
        assignedSourceSystems,
        assignedProjects,
      } = extractUserObjects(userObjects);

      const { uname: username, tenantId } = parseJwt(token) || {};

      state.isAuthenticated = isAuthenticated;
      const permissionSet = transformUserFunctionality(permissions);
      state.loginUser = {
        user,
        username,
        assignedDivisions,
        assignedClients,
        assignedLocations,
        assignedSourceSystems,
        assignedProjects,
        dateFormat,
        dateTimeFormat,
        permissionSet,
        tenantId,
        tenantList,
        customPermissions
      };
    },
    logout(state) {
      state.isAuthenticated = false;
      state.loginUser = null;
    },
    startImpersonating(state, action) {
      const {
        UserAccountID,
        UserID,
        Username,
        dateFormat,
        dateTimeFormat,
        user,
        roleClaims,
        userObjects,
        tenantList,
      } = action.payload;

      const {
        assignedDivisions,
        assignedClients,
        assignedLocations,
        assignedSourceSystems,
        assignedProjects,
      } = extractUserObjects(userObjects);

      const permissionSet = parseRoleClaims(roleClaims);

      state.impersonatedUser = {
        UserAccountID,
        UserID,
        Username,
        dateFormat,
        dateTimeFormat,
        user,
        assignedDivisions,
        assignedClients,
        assignedLocations,
        assignedSourceSystems,
        assignedProjects,
        permissionSet,
        tenantList,
      };

      state.isImpersonateing = true;
    },
    stopImpersonating(state) {
      state.isImpersonateing = false;
      state.impersonatedUser = null;
    },
    updateAccessToken(state, action) {
      const { tenantId } = parseJwt(action.payload) || {};
      state.loginUser.tenantId = tenantId;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
