import axios from 'axios';
import apiConfig from '../apiConfig';
import { getAxiosInstance } from '../../utils/axiosApi';

const axiosInstance = getAxiosInstance({
  baseUrl: `${apiConfig.IAMUrl}`,
});

const axiosMInstance = getAxiosInstance({
  baseUrl: `${apiConfig.PortalAPIUrl}`,
});
const searchUser = async ({
  firstName,
  lastName,
  email,
  page,
  pageSize,
  sortBy,
}) => {
  const queryParams = [];
  if (firstName && firstName.trim() !== '') {
    queryParams.push(`firstName=${encodeURIComponent(firstName.trim())}`);
    queryParams.push('firstNameOP=Contains');
  }

  if (lastName && lastName.trim() !== '') {
    queryParams.push(`lastName=${encodeURIComponent(lastName.trim())}`);
    queryParams.push('lastNameOP=Contains');
  }

  if (email) {
    queryParams.push(`email=${encodeURIComponent(email.trim())}`);
    queryParams.push('emailOP=Contains');
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  try {
    const response = await axiosInstance.get(
      `/User/users/all?${queryParams.join('&')}`
    );

    return response.data;
  } catch (e) {
    // console.log(axios.isCancel);
    // console.log("axios request cancelled");
    // if (axios.isCancel(err)) {
    //   console.log("axios request cancelled");
    //   return {};
    // }

    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

const getUserByUserId = async ({ userId }) => {
  try {
    const response = await axiosInstance.get(
      `/User/users/id/${userId}/object/all/role/all`
    );
    return response.data;
  } catch (e) {
    throw new Error('Error getUserByUserId');
  }
};

const UpdateUser = async ({ updateUser }) => {
  try {
    const response = await axiosInstance.post(
      '/User/detail/update',
      updateUser
    );
    return response.data;
  } catch (e) {
    throw new Error('Error getUserByUserId');
  }
};
const getUserMasterdata = async () => {
  try {
    const response = await axiosMInstance.get('/MasterData/timezones/all');
    return response.data;
  } catch (e) {
    throw new Error('Error getUserMasterdata');
  }
};
const getAllRolesdata = async () => {
  try {
    const response = await axiosInstance.get('/User/roles/all');
    return response.data;
  } catch (e) {
    throw new Error('Error getUserMasterdata');
  }
};

const getAllLocationdata = async () => {
  try {
    const queryParams = [];
    queryParams.push(`pageSize=${99999}`);
    queryParams.push(`currentPage=${1}`);
    const queryParamVal = queryParams.join('&');
    const response = await axiosMInstance.get(
      `/masterdata/locations/all?${queryParamVal}`
    );
    return response.data;
  } catch (e) {
    throw new Error('Error getAllLocationdata');
  }
};

const searchUserAccount = async ({
  username,
  usernameOp,
  loginType,
  restrictedClientIds,
  page,
  pageSize,
  sortBy,
}) => {
  const queryParams = [];
  if (username && username.trim() !== '') {
    queryParams.push(`username=${encodeURIComponent(username.trim())}`);

    if (usernameOp && usernameOp.trim() !== '') {
      queryParams.push(`usernameOP=${encodeURIComponent(usernameOp.trim())}`);
    } else {
      queryParams.push('usernameOP=Contains');
    }
  }

  if (loginType && loginType.trim() !== '') {
    queryParams.push(`loginType=${encodeURIComponent(loginType.trim())}`);
  }

  if (Array.isArray(restrictedClientIds) && restrictedClientIds.length > 0) {
    queryParams.push(`restrictedClientIds=${restrictedClientIds.join(',')}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  try {
    const response = await axiosInstance.get(
      `/User/useraccounts/all?${queryParams.join('&')}`
    );

    return response.data;
  } catch (e) {
    // console.log(axios.isCancel);
    // console.log("axios request cancelled");
    // if (axios.isCancel(err)) {
    //   console.log("axios request cancelled");
    //   return {};
    // }

    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

const getUserDataWithRoleByUserID = async ({ userId }) => {
  try {
    const response = await axiosInstance.get(
      `/User/users/id/${userId}/object/all/role/all`
    );

    // console.log("Search job result");
    // console.log(response);
    // {
    //   User,
    //   UserObjects,
    //   RoleClaims
    // }
    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

// Role Management API (~ USER API)
const searchRoles = async ({ roleName, active, page, pageSize, sortBy }) => {
  const queryParams = [];

  if (roleName && roleName.trim() !== '') {
    queryParams.push(`roleName=${encodeURIComponent(roleName.trim())}`);
    queryParams.push('roleNameOp=Contains');
  }

  if (active !== null) {
    queryParams.push(`active=${active}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  try {
    const response = await axiosInstance.get(
      `User/roles/all?${queryParams.join('&')}`
    );

    return response.data;
  } catch (error) {
    if (!axios.isCancel(error)) {
      throw error;
    }
  }

  return {};
};

const RegisterUser = async (registerUser) => {
  try {
    const response = await axiosInstance.post('/User/register', registerUser);
    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

const resetClientPassword = async ({ tokenId, newPassword }) => {
  if (!tokenId || !newPassword) {
    throw new Error('Please check your password');
  }

  try {
    await axiosInstance.post('/user/customers/resetpassword', {
      RecoveryId: tokenId,
      Password: newPassword,
    });

    return true;
  } catch (e) {
    throw new Error('Please check your password');
  }
};

const UserApi = {
  searchUser,
  getUserMasterdata,
  getAllRolesdata,
  getUserDataWithRoleByUserID,
  searchUserAccount,
  getUserByUserId,
  searchRoles,
  getAllLocationdata,
  UpdateUser,
  RegisterUser,
  resetClientPassword,
};

export default UserApi;
