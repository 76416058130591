import { createSlice } from '@reduxjs/toolkit';

const details = {
  location: null,
  title: { id: 0, text: 'Mr' },
  firstName: '',
  lastName: '',
  company: '',
  contacts: [
    {
      type: { id: 0, text: 'Phone' },
      Phone: '',
      Email: '',
      Fax: '',
      IsDefault: true,
    },
  ],
};

const address = [
  {
    AddressLine1: '',
    AddressLine2: '',
    AddressLine3: '',
    City: '',
    State: '',
    PostCode: '',
    Country: '',
    AddressName: '',
    CountryObj: null,
    IsDefault: true,
  }
];

const registerDetails = {
  emailId: '',
  password: '',
  confirmPassword: '',
};

const agreement = {
  termCondition: false,
  privacyCookies: false,
};

const initialState = {
  userDetails: details,
  userAddress: address,
  userRegisterDetails: registerDetails,
  userAgreement: agreement
};

const userRegistration = createSlice({
  name: 'userRegistration',
  initialState,
  reducers: {
    updateUserDetails(state, action) {
      state.userDetails[action.payload.propName] = action.payload.propValue;
    },

    updateUserContactDetails(state, action) {
      const { propName, propValue, index } = action.payload;
      if (state.userDetails.contacts[index] === undefined) {
        state.userDetails.contacts.push({ Email: propValue,
          Fax: '',
          IsDefault: true,
          Phone: '',
          type: { id: 1, text: 'Email' } });
      }
      state.userDetails.contacts[index][propName] = propValue;
    },

    autoUpdateDefaultValue(state) {
      const prevPhoneActive = state.userDetails.contacts.indexOf(
        state.userDetails.contacts.find(
          (contactData) => contactData.type.text === 'Phone' && contactData.default === true
        )
      );

      const prevEmailActive = state.userDetails.contacts.indexOf(
        state.userDetails.contacts.find(
          (contactData) => contactData.type.text === 'Email' && contactData.default === true
        )
      );

      if (prevPhoneActive === -1) {
        const index = state.userDetails.contacts.findIndex(
          (contactData) => contactData.type.text === 'Phone' && contactData.default === false
        );
        if (index !== -1) {
          for (let i = 0; i < state.userDetails.contacts.length; i++) {
            if (state.userDetails.contacts[i].type.text === 'Phone') {
              state.userDetails.contacts[i].default = false;
            }
          }
          if (state.userDetails.contacts[index].type.text === 'Phone') {
            state.userDetails.contacts[index].default = true;
          }
        }
      }

      if (prevEmailActive === -1) {
        const index = state.userDetails.contacts.findIndex(
          (contactData) => contactData.type.text === 'Email' && contactData.default === false
        );
        if (index !== -1) {
          for (let i = 0; i < state.userDetails.contacts.length; i++) {
            if (state.userDetails.contacts[i].type.text === 'Email') {
              state.userDetails.contacts[i].default = false;
            }
          }
          state.userDetails.contacts[index].default = true;
        }
      }
    },

    autoUpdateDuplicateDefaultValue(state) {
      const phoneIndex = state.userDetails.contacts.findIndex(
        (contactData) => contactData.type.text === 'Phone' && contactData.default === true
      );
      const emailIndex = state.userDetails.contacts.findIndex(
        (contactData) => contactData.type.text === 'Email' && contactData.default === true
      );
      let duplicateDefaulPhone = 0;
      let duplicateDefaulEmail = 0;
      if (phoneIndex !== -1 || emailIndex !== -1) {
        state.userDetails.contacts.forEach((contactData) => {
          if (contactData.type.text === 'Phone' && contactData.default === true) {
            duplicateDefaulPhone += 1;
          }
          if (contactData.type.text === 'Email' && contactData.default === true) {
            duplicateDefaulEmail += 1;
          }
        });

        if (duplicateDefaulPhone > 1) {
          state.userDetails.contacts.forEach((contactData) => {
            if (contactData.type.text === 'Phone') contactData.default = false;
          });
          state.userDetails.contacts[phoneIndex].default = true;
        }

        if (duplicateDefaulEmail > 1) {
          state.userDetails.contacts.forEach((contactData) => {
            if (contactData.type.text === 'Email') contactData.default = false;
          });
          state.userDetails.contacts[emailIndex].default = true;
        }
      }
    },

    updateDefaultValue(state, action) {
      const { index, type } = action.payload;
      if (type === 'Phone') {
        for (let i = 0; i < state.userDetails.contacts.length; i++) {
          if (state.userDetails.contacts[i].type.text === 'Phone') {
            state.userDetails.contacts[i].default = false;
          }
        }
        state.userDetails.contacts[index].default = true;
      }

      if (type === 'Email') {
        for (let i = 0; i < state.userDetails.contacts.length; i++) {
          if (state.userDetails.contacts[i].type.text === 'Email') {
            state.userDetails.contacts[i].default = false;
          }
        }
        state.userDetails.contacts[index].default = true;
      }
    },

    updateUserAddressDetails(state, action) {
      const { propName, propValue, index } = action.payload;
      if (propName === 'Country') {
        state.userAddress[index][propName] = propValue.text;
      } else {
        state.userAddress[index][propName] = propValue;
      }
    },

    updateDefaultAddress(state, action) {
      const { index } = action.payload;
      const prevActive = state.userAddress.indexOf(
        state.userAddress.find(
          (addressData) => addressData.default === true
        )
      );
      if (prevActive !== -1) {
        state.userAddress[prevActive].default = false;
      }
      state.userAddress[index].default = true;
    },

    addMoreContactDetails(state, action) {
      state.userDetails.contacts.push(action.payload);
    },

    removeContactDetails(state, action) {
      const { index } = action.payload;
      state.userDetails.contacts.splice(index, 1);
    },

    addMoreAddress(state, action) {
      state.userAddress.push(action.payload);
    },

    removeAddress(state, action) {
      const { index } = action.payload;
      if (state.userAddress[index].default === true) {
        const firstAddress = state.userAddress.findIndex((addressData) => addressData.default === false);
        if (firstAddress !== -1) {
          state.userAddress[firstAddress].default = true;
        }
      }
      state.userAddress.splice(index, 1);
    },

    updateUserRegisterDetails(state, action) {
      state.userRegisterDetails[action.payload.propName] = action.payload.propValue;
    },

    updateUserAgreement(state, action) {
      state.userAgreement[action.payload.propName] = action.payload.propValue;
    },

    reset: () => initialState,
  },
});

export const userRegistrationActions = userRegistration.actions;
export default userRegistration.reducer;
