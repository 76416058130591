import { createSlice } from '@reduxjs/toolkit';
import { getVisibleColumns } from '../../../../services/ObjectConfiguration/ObjectConfiguration.service';
import { LoadingStatus, SortDirection } from '../../../../utils/constants';

const applySaveFilterHandler = (state, action) => {
  const fields = action.payload;

  if (!Array.isArray(fields) || fields.length === 0) {
    return;
  }
  const fieldObj = {};
  fields.forEach(({ Field, Value, Text }) => {
    fieldObj[Field] = Value;
    if (Text) {
      fieldObj[`${Field}_Text`] = Text;
    }
  });

  [
    'from',
    'to',
    'referenceNo',
    'awbNO',
    'IMO',
    'VesselName',
    'ClientName',
  ].forEach((fieldName) => {
    const fieldValue = fieldObj[fieldName];
    state.filter[fieldName] = fieldValue || '';
  });

  state.filter['statuses'] = fieldObj['statuses'] ?? [];
};

const initialFilterState = {
  from: '',
  to: '',
  referenceNo: '',
  awbNo: '',
  IMO: '',
  VesselName: '',
  ClientName: '',

  statuses: [],
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'EarliestPickupOn',
  sortDirection: SortDirection.Desc,
};

const orderDetails = {
  parcelDetails: {
    NumberOfParcel: '2',
    IMODetails: [{ IMONumber: '9455387', VesselName: 'HK Ship' }],
  },
  pickUpDateTime: {
    EarliestDateTime: '10/11/2021 09:00',
    LatestDateTime: '10/11/2021 17:00',
    BunkerPort: 'HK Bunker',
  },
  senderDetails: {
    SenderName: 'Hammad',
    CompanyName: 'Bureau Veritas',
    Phone: '61438114109',
    Email: 'hammadkhan@bureauVeritas.com',
  },
  senderAddress: {
    FirstLine: '3/435 Willamstown',
    SecondLine: '',
    ThirdLine: '',
    TownCity: 'Port Melbourne',
    State: 'Victoria',
    Country: 'Australia',
    PostCode: '3331',
  },
  documentDetails: {
    referenceNo: '12349874',
    document: [
      { type: 'pdf', documentName: '12349874', file: '#' },
      { type: 'pdf', documentName: 'Commercial Invoice', file: '#' },
      { type: 'pdf', documentName: 'MSDS', file: '#' },
    ],
  },
  orderStatus: {
    status: 'In Transit',
    date: '12/11/2021',
    message: 'Courier update',
  },
  createdOn: '5/16/2022'
};

const tracking = {
  id: '',
};

const initialState = {
  filter: initialFilterState,
  tableInfo: initialPageState,
  orders: [],
  totalOrderCount: 0,
  orderFilterData: [],
  totalFilterCount: 0,
  loadingStatus: LoadingStatus.Idle, // idle, pending, success, failed
  page: 1,
  sort: null,
  isFilterVisible: true,
  isSearching: false,
  visibleGridColumns: [],
  mergedColumns: [],
  loadingGridColumnStatus: LoadingStatus.Idle,
  isSavedFilterLoaded: false,
  savedFilterList: [],
  loadingFilterSettingtatus: LoadingStatus.Idle,
  userOrderDeatils: orderDetails,
  userOrderTracking: tracking,
  onTableMode: false,
};

const orderTrackingSlice = createSlice({
  name: 'orderTracking',
  initialState,
  reducers: {
    showHideFilter(state, action) {
      state.isFilterVisible = action.payload;
    },
    updateFilterValue(state, action) {
      state.filter[action.payload.propName] = action.payload.propValue;
    },
    orderTrackingFilter(state) {
      state.filter = { ...initialFilterState };
    },
    resetSearchFilter(state, action) {
      state.filter = { ...initialFilterState, ...action.payload };
      state.tableInfo = { ...initialPageState };
    },
    updatePageInfoField(state, action) {
      const { propName, propValue } = action.payload;
      state.tableInfo[propName] = propValue;
    },
    setPage(state, action) {
      state.tableInfo.page = action.payload;
    },
    updateLoadingStatus(state, action) {
      state.loadingStatus = action.payload;
    },
    searchOrder(state, action) {
      state.orders = action.payload.orderTracking;
      state.totalOrderCount = action.payload.totalOrderCount;
    },

    orderFilter(state, action) {
      state.orderFilterData = action.payload.ordersFilter;
      state.totalFilterCount = action.payload.totalFilterCount;
    },

    // Start - Grid Columns Setting Actions
    updateUserColumnSettings(state, action) {
      state.mergedColumns = action.payload;
      state.visibleGridColumns = getVisibleColumns(action.payload);
    },

    updateLoadingColumnSettingStatus(state, action) {
      state.loadingGridColumnStatus = action.payload;
    },

    updateVisibleGridColumns(state, action) {
      state.visibleGridColumns = action.payload;
    },
    updateMergedGridColumns(state, action) {
      state.mergedColumns = action.payload;
    },
    // End - Grid Columns Setting

    updateSavedFilterList(state, action) {
      state.isSavedFilterLoaded = true;
      state.savedFilterList = action.payload;
    },

    applySavedFilter(state, action) {
      applySaveFilterHandler(state, action);
    },

    updateLoadingFilterSettingStatus(state, action) {
      state.loadingFilterSettingtatus = action.payload;
    },

    updateOrderTrackingValue(state, action) {
      state.userOrderTracking[action.payload.propName] = action.payload.propValue;
    },
    updateSearchResult(state, action) {
      const { orders, totalOrderCount } = action.payload;

      state.orders = orders;
      state.totalOrderCount = totalOrderCount;
    },
    updateIsSearching(state, action) {
      state.isSearching = !!action.payload;
    },
    updateTableMode(state, action) {
      state.onTableMode = action.payload;
    },
    updateOrderDetailsFields(state, action) {
      state.userOrderDeatils[action.payload.propName] = action.payload.propValue;
    }
  },
});

export const filterFields = {
  from: 'from',
  to: 'to',
  referenceNo: 'referenceNo',
  awbNo: 'awbNo',
  status: 'statuses',
  IMO: 'IMO',
  VesselName: 'VesselName',
  ClientName: 'ClientName',
};

export const tableInfoFields = {
  page: 'page',
  pageSize: 'pageSize',
  sortColumn: 'sortColumn',
  sortDirection: 'sortDirection',
};

export const orderTrackingActions = orderTrackingSlice.actions;
export default orderTrackingSlice.reducer;
