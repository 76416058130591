import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Divider, Link, Typography, useMediaQuery } from '@mui/material';

// import AuthBanner from '../../components/authentication/AuthBanner';

// import useAuth from "../../hooks/useAuth";

import LegalBar from '../../components/layouts/LegalBar';

import { LoginForm } from './components';

const whiteTextColor = '#f4f5f7';

const Login = () => {
  // const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  // const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  // const xsUp = useMediaQuery((theme) => theme.breakpoints.up("xs"));

  // const isLoginCenter = xsUp || smUp;

  const flexDirection = 'column';
  const minHeightForm = smUp ? '567px' : '520px';

  return (
    <>
      <Helmet>
        <title> Sample Transport | Login</title>
      </Helmet>

      <Box
        sx={{
          background: "url('/static/images/bv-transport-1.png')",
          backgroundPosition: 'center bottom',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          flexDirection: { flexDirection },
          minHeight: '100vh',
        }}
      >
        {/* sx={{ position: "fixed", top: 0, left: 0 }} */}
        {/* <Box sx={{ maxWidth: '400px' }}>
          <img
            alt="BV Evaluate"
            src="/static/images/iEval_Logo.png"
            style={{ width: '60%' }}
          />
      </Box> */}

        {/* {smUp && <Box sx={{ flexGrow: 1 }} />} */}
        {smUp && <Box sx={{ flexGrow: 1 }} />}

        <Box
          sx={{
            // marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Container maxWidth="xs" sx={{ minHeight: minHeightForm, mr: 10, mb: 10, mt: 15 }}>
            {/* sx={{ position: "fixed", top: 0, left: 0 }} */}
            {/* <Box>
              <img
                alt="Access BV Logo"
                src="/static/images/AccessBV_Logo.png"
                style={{ width: "100%" }}
              />
            </Box> */}
            <Card
              sx={{
                backdropFilter: 'blur(10px)',
                backgroundColor: 'rgba(21, 21, 21, .6)',
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  p: 4,
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    m: -4,
                    mb: 3,
                  }}
                >
                  <Box
                    sx={{
                      background: "url('/static/images/bg-login-form.png')",
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      height: 150,
                      width: '100%',
                      opacity: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '100%',
                        p: 1,
                      }}
                    >
                      <img alt="BV Logo" src="/static/images/BVLogoTrans.png" />
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    mt: 1,
                  }}
                >
                  <LoginForm />
                </Box>

                <Divider sx={{ my: 1 }} />
                <Box>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ color: whiteTextColor }}
                  >
                    Not Registered?
                  </Typography>
                  <Link
                    color="#29d"
                    component={RouterLink}
                    to="/userRegistration"
                    variant="body2"
                    sx={{ ml: '4px' }}
                  >
                    Register Now
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Container>

          {/* {lgUp && <Box sx={{ flexGrow: 1 }} />} */}
        </Box>

        <Box sx={{ flexGrow: 2 }} />
      </Box>

      {smUp && <LegalBar displayLogo displayCopyRight isLogin />}
    </>
  );
};

export default Login;
