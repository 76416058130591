import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const useAuth = () => {
  const { isAuthenticated, loginUser, impersonatedUser } = useSelector(
    (state) => state.auth
  );
  let {
    assignedDivisions,
    assignedClients,
    assignedLocations,
    assignedSourceSystems,
    assignedProjects,
    dateFormat,
    dateTimeFormat,
    permissionSet,
    customPermissions,
  } = loginUser || {};

  const { token, username, user } = loginUser || {};
  const { UserID } = user || {};

  if (impersonatedUser) {
    assignedDivisions = impersonatedUser.assignedDivisions;
    assignedClients = impersonatedUser.assignedClients;
    assignedLocations = impersonatedUser.assignedLocations;
    assignedSourceSystems = impersonatedUser.assignedSourceSystems;
    assignedProjects = impersonatedUser.assignedProjects;
    dateFormat = impersonatedUser.dateFormat;
    dateTimeFormat = impersonatedUser.dateTimeFormat;
    permissionSet = impersonatedUser.permissionSet;
    customPermissions = impersonatedUser.customPermissions;
  } else {
    dateFormat = 'dd-MMM-yyyy';
    dateTimeFormat = 'dd/MMM/yyyy HH:mm';
  }

  const isAuthorized = useCallback(
    ({ permissionCode }) => {
      if (!permissionSet || !permissionCode) {
        return false;
      }

      return permissionSet[permissionCode.toLowerCase()];
    },
    [permissionSet]
  );

  const hasCustomPermission = useCallback(
    ({ customFunction }) => {
      if (!Array.isArray(customPermissions) || !customFunction) {
        return false;
      }

      return customPermissions.includes(customFunction.toLowerCase());
    },
    [customPermissions]
  );

  return {
    isAuthenticated,
    user,
    token,
    username,
    permissionSet,
    assignedDivisions,
    assignedClients,
    assignedLocations,
    assignedSourceSystems,
    assignedProjects,
    dateFormat,
    dateTimeFormat,
    userId: UserID,
    isAuthorized,
    hasCustomPermission,
  };
};

export default useAuth;
