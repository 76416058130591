import AppSettingApi from '../api/AppSettingApi';

const combineApplyingColumns = ({ availableColumns, userSettingColumns }) => {
  if (!Array.isArray(availableColumns)) {
    return [];
  }

  const userSettingListObject = userSettingColumns || {};
  return availableColumns
    .map((col) => {
      const clonedCol = { ...col };
      const userSettingObj = userSettingListObject[clonedCol.ColName.toLocaleLowerCase()];
      if (!userSettingObj) {
        return clonedCol;
      }

      clonedCol.Visible = userSettingObj.Visible;
      clonedCol.Sequence = userSettingObj.Sequence;
      return clonedCol;
    })
    .sort((col1, col2) => col1.Sequence - col2.Sequence);
};

export const getVisibleColumns = (mergedColumns = []) => mergedColumns.filter((col) => col.Visible).sort((col1, col2) => col1.Sequence - col2.Sequence);

export async function loadColumnSettingConfiguration({ userId, objectType }) {
  const objectConfigParams = [
    {
      Type: objectType,
      Belong: '*',
      ID: '*',
    },
  ];

  if (userId) {
    objectConfigParams.push({
      Type: objectType,
      Belong: 'User',
      ID: `${userId}`,
    });
  }

  const objectConfigurations = await AppSettingApi.getObjectConfigurationsByMultiTypeAndBelongsToRecordids(
    objectConfigParams
  );
  const availableColsObj = {};

  const userSettingCols = {};

  if (Array.isArray(objectConfigurations)) {
    objectConfigurations
      .filter((item) => item.BelongsTo === '*')
      .forEach(({ Config }) => {
        const gridColSettings = JSON.parse(Config);
        if (!Array.isArray(gridColSettings) || gridColSettings.length === 0) {
          return;
        }

        gridColSettings.forEach((col) => {
          if (!col.ColField || availableColsObj[`${col.ColField.toLocaleLowerCase()}`]) {
            return;
          }

          availableColsObj[`${col.ColField.toLocaleLowerCase()}`] = col;
        });
      });

    objectConfigurations
      .filter((item) => item.BelongsTo === 'User')
      .forEach(({ Config }) => {
        const gridColSettings = JSON.parse(Config);
        if (!Array.isArray(gridColSettings) || gridColSettings.length === 0) {
          return;
        }

        gridColSettings.forEach((col, index) => {
          col.Sequence = index;
          userSettingCols[`${col.FieldName.toLocaleLowerCase()}`] = col;
        });
      });
  }

  const avaiableCols = Object.values(availableColsObj);

  const visibleCols = avaiableCols
    .map((col) => {
      const clonedCol = { ...col };
      const userSettingObj = userSettingCols[clonedCol.ColName.toLocaleLowerCase()];
      if (!userSettingObj) {
        return clonedCol;
      }

      clonedCol.Visible = userSettingObj.Visible;
      clonedCol.Sequence = userSettingObj.Sequence;
      return clonedCol;
    })
    .filter((x) => x.Visible)
    .sort((col1, col2) => col1.Sequence - col2.Sequence);

  // Sortable Columns
  const _sortColumns = avaiableCols.filter((col) => col.Orderable);

  const mergedColumns = combineApplyingColumns({
    availableColumns: avaiableCols,
    userSettingColumns: userSettingCols,
  });

  return {
    availableColumns: avaiableCols,
    userSettingColumns: userSettingCols,
    visibleColumns: visibleCols,
    sortableColumns: _sortColumns,
    combineApplyingColumns: mergedColumns,
  };
}

// This is to get current filter preset list
export const getCurrentFilterPresetList = async ({ objectType, userId }) => {
  if (!objectType || !userId) {
    return [];
  }

  const objectConfigParams = [
    {
      Type: objectType,
      Belong: 'User',
      ID: `${userId}`,
    },
  ];

  try {
    const objectConfigurations = await AppSettingApi.getObjectConfigurationsByMultiTypeAndBelongsToRecordids(
      objectConfigParams
    );
    if (Array.isArray(objectConfigurations) && objectConfigurations.length) {
      const presetList = JSON.parse(objectConfigurations[0].Config);
      return presetList;
    }
  } catch (error) {
    console.log(error);
  }

  return [];
};

// This is to Update Filter Presets
export const updateFilterPresetList = async ({ objectType, filterPresets, userId }) => {
  if (!userId || !objectType) {
    return;
  }

  const objectConfigParams = [
    {
      Type: objectType,
      Belong: 'User',
      ID: `${userId}`,
    },
  ];

  const objectConfigurations = await AppSettingApi.getObjectConfigurationsByMultiTypeAndBelongsToRecordids(
    objectConfigParams
  );

  const currentObjectConifg = Array.isArray(objectConfigurations) && objectConfigurations.length > 0 ? objectConfigurations[0] : null;

  const objectConfigID = currentObjectConifg ? currentObjectConifg.ObjectConfigurationID : -1;

  const saveDataObject = {
    objectType,
    belongsTo: 'User',
    recordID: `${userId}`,
    fields: filterPresets,
    updatedBy: `${userId}`,
    objectConfigurationID: objectConfigID,
  };

  await AppSettingApi.insertOrUpdateObjectConfiguration(saveDataObject);
};

// This is to Save Filter Default Values Settings
export const saveFilterDefaultSettings = async ({ objectType, filterName, defaultSettings, userId, isDefault }) => {
  const objectConfigParams = [];

  if (userId) {
    objectConfigParams.push({
      Type: objectType,
      Belong: 'User',
      ID: `${userId}`,
    });
  }

  const objectConfigurations = await AppSettingApi.getObjectConfigurationsByMultiTypeAndBelongsToRecordids(
    objectConfigParams
  );
  const currentObjectConifg = Array.isArray(objectConfigurations) && objectConfigurations.length > 0 ? objectConfigurations[0] : null;

  let currentUserFilters = [];
  if (currentObjectConifg) {
    try {
      currentUserFilters = JSON.parse(currentObjectConifg.Config) || [];
    } catch (error) {
      console.log(error);
    }
  }

  let existingFilterSetting = currentUserFilters.filter(
    (x) => x.Name.toLocaleLowerCase() === filterName.toLocaleLowerCase()
  )[0];

  if (existingFilterSetting) {
    existingFilterSetting.Fields = defaultSettings;
  } else {
    existingFilterSetting = {
      Name: filterName,
      Fields: defaultSettings,
    };

    currentUserFilters.push(existingFilterSetting);
  }

  // If Default is true, then mark other filter as Not Default
  if (isDefault) {
    currentUserFilters.forEach((filter) => {
      filter.Default = false;
    });

    existingFilterSetting.Default = true;
  }

  const saveDataObject = {
    objectType,
    belongsTo: 'User',
    recordID: `${userId}`,
    fields: currentUserFilters,
    updatedBy: `${userId}`,
    objectConfigurationID: currentObjectConifg ? currentObjectConifg.ObjectConfigurationID : -1,
  };

  await AppSettingApi.insertOrUpdateObjectConfiguration(saveDataObject);

  return currentUserFilters;
};

// Load Filter Item Setting Configuration for search screen
export async function loadFilterItemSettingConfiguration({ userId, objectType }) {
  const objectConfigParams = [
    {
      Type: objectType,
      Belong: '*',
      ID: '*',
    },
  ];

  if (userId) {
    objectConfigParams.push({
      Type: objectType,
      Belong: 'User',
      ID: `${userId}`,
    });
  }

  const objectConfigurations = await AppSettingApi.getObjectConfigurationsByMultiTypeAndBelongsToRecordids(
    objectConfigParams
  );
  const availableColsObj = {};

  const userSettingCols = {};

  if (Array.isArray(objectConfigurations)) {
    objectConfigurations
      .filter((item) => item.BelongsTo === '*')
      .forEach(({ Config }) => {
        const gridColSettings = JSON.parse(Config);
        if (!Array.isArray(gridColSettings) || gridColSettings.length === 0) {
          return;
        }

        gridColSettings.forEach((col) => {
          if (!col.Field || availableColsObj[`${col.Field.toLocaleLowerCase()}`]) {
            return;
          }

          availableColsObj[`${col.Field.toLocaleLowerCase()}`] = col;
        });
      });

    objectConfigurations
      .filter((item) => item.BelongsTo === 'User')
      .forEach(({ Config }) => {
        const gridColSettings = JSON.parse(Config);
        if (!Array.isArray(gridColSettings) || gridColSettings.length === 0) {
          return;
        }

        gridColSettings.forEach((col, index) => {
          col.Order = index;
          userSettingCols[`${col.Field.toLocaleLowerCase()}`] = col;
        });
      });
  }

  const avaiableCols = Object.values(availableColsObj);

  const userSettingListObject = userSettingCols || {};

  const sortedMergeItems = avaiableCols
    .map((col) => {
      const clonedCol = { ...col };
      const userSettingObj = userSettingListObject[clonedCol.Field.toLocaleLowerCase()];
      if (!userSettingObj) {
        return clonedCol;
      }

      clonedCol.Visible = userSettingObj.Visible;
      clonedCol.Order = userSettingObj.Order;
      return clonedCol;
    })
    .sort((col1, col2) => col1.Order - col2.Order);

  return {
    availableFields: sortedMergeItems,
  };
}
