export const SortDirection = {
  Asc: 'Asc',
  Desc: 'Descending',

  combineSortBy: (col, direction) => {
    if (direction === 'Asc') {
      return col;
    }

    return col + direction;
  },
};
