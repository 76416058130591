export const ObjectTypes = {
  RecentJobGrid: 'RecentJobGrid',
  SampleSearchGrid: 'SampleSearchGrid',
  RootCauseSearchGrid: 'RootCauseSearchGrid',
  RoleSearchGrid: 'RoleSearchGrid',
  DocumentSearchGrid: 'DocumentSearchGrid',
  RequestSearchGrid: 'RequestSearchGrid',
  ActionSearchGrid: 'ActionSearchGrid',
  IssueTypeSearchGrid: 'IssueTypeSearchGrid',
  LocationSearchGrid: 'LocationSearchGrid',
  CountrySearchGrid: 'CountrySearchGrid',
  RootCauseCategorySearchGrid: 'RootCauseCategorySearchGrid',
  RequestCategorySourceGrid: 'RequestCategorySourceGrid',
  EmailSearchGrid: 'EmailSearchGrid',
  RiskRankingGrid: 'RiskRankingGrid',
  RoleSearchUserFilter: 'RoleSearchUserFilter',
  NewsSearchUserFilter: 'NewsSearchUserFilter',
  DocumentSearchUserFilter: 'DocumentSearchUserFilter',
  UserSearchUserFilter: 'UserSearchUserFilter',
  RootCauseSearchUserFilterItemSetting: 'RootCauseSearchUserFilterItemSetting',
  RootCauseSearchUserFilter: 'RootCauseSearchUserFilter',
  LocationSearchUserFilterItemSetting: 'LocationSearchUserFilterItemSetting',
  LocationSearchUserFilter: 'LocationSearchUserFilter',
  RegionManagementUserFilterItemSetting: 'RegionManagementUserFilterItemSetting',
  RegionManagementUserFilter: 'RegionManagementUserFilter',
  RegionManagementGrid: 'RegionManagementGrid',
  RootCauseNameSearchUserFilterItemSetting: 'RootCauseNameSearchUserFilterItemSetting',
  RootCauseNameSearchUserFilter: 'RootCauseNameSearchUserFilter',
  ProbabilitySearchUserFilterItemSetting: 'ProbabilitySearchUserFilterItemSetting',
  ProbabilitySearchUserFilter: 'ProbabilitySearchUserFilter',
  SeveritySearchUserFilterItemSetting: 'SeveritySearchUserFilterItemSetting',
  SeveritySearchUserFilter: 'SeveritySearchUserFilter',
  CompanySearchUserFilterItemSetting: 'CompanySearchUserFilterItemSetting',
  CompanySearchUserFilter: 'CompanySearchUserFilter',
  IssueTypeSearchUserFilterItemSetting: 'IssueTypeSearchUserFilterItemSetting',
  IssueTypeSearchUserFilter: 'IssueTypeSearchUserFilter',
  CountrySearchUserFilterItemSetting: 'CountrySearchUserFilterItemSetting',
  CountrySearchUserFilter: 'CountrySearchUserFilter',
  RootCauseCategorySearchUserFilterItemSetting: 'RootCauseCategorySearchUserFilterItemSetting',
  RootCauseCategorySearchUserFilter: 'RootCauseNameSearchUserFilter',
  RequestCategorySourceUserFilterItemSetting: 'RequestCategorySourceUserFilterItemSetting',
  RequestCategorySourceUserFilter: 'RequestCategorySourceUserFilter',
  EmailSearchUserFilterItemSetting: 'EmailSearchUserFilterItemSetting',
  RequestUserFilterItemSetting: 'RequestUserFilterItemSetting',
  RequestUserFilter: 'RequestUserFilter',
  EmailSearchUserFilter: 'EmailSearchUserFilter',
  RequestCategorySearchGrid: 'RequestCategorySearchGrid',
  RequestCategorySearchUserFilterItemSetting: 'RequestCategorySearchUserFilterItemSetting',
  RequestCategorySearchUserFilter: 'RequestCategorySearchUserFilter',
  MappingSearchGrid: 'MappingSearchGrid',
  MappingSearchUserFilterItemSetting: 'MappingSearchUserFilterItemSetting',
  MappingSearchUserFilter: 'MappingSearchUserFilter',
  RiskRankingUserFilterItemSetting: 'RiskRankingUserFilterItemSetting',
  RiskRankingUserFilter: 'RiskRankingUserFilter',
  ActionSearchUserFilterItemSetting: 'ActionSearchUserFilterItemSetting',
  ActionSearchUserFilter: 'ActionSearchUserFilter',
  RootCauseNameSearchGrid: 'RootCauseNameSearchGrid',
  CompanySearchGrid: 'CompanySearchGrid',
  RoleManagementUserFilterItemSetting: 'RoleManagementUserFilterItemSetting',
  OrderTrackingUserFilterItemSetting: 'OrderTrackingUserFilterItemSetting',
  OrderTrackingUserFilter: 'OrderTrackingUserFilter',
  OrderTrackingGrid: 'OrderTrackingGrid',
  DashboardUserFilterItemSetting: 'DashboardUserFilterItemSetting',
  DashboardUserFilter: 'DashboardUserFilter',
  DashboardGrid: 'DashboardGrid',
  ForwardOrderUserFilterItemSetting: 'ForwardOrderUserFilterItemSetting',
  ForwardOrderUserFilter: 'ForwardOrderUserFilter',
  ForwardOrderGrid: 'ForwardOrderGrid',
};
