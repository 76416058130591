import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import AuthorizationError from '../../../pages/error/AuthorizationError';

const PermissionGuard = ({ permission, right, children }) => {
  const { isAuthorized } = useAuth();

  const isAllowed = isAuthorized({ permissionCode: permission, right });
  if (!isAllowed) {
    return <AuthorizationError />;
  }

  return <>{children}</>;
};

PermissionGuard.propTypes = {
  children: PropTypes.node,
};

export const PermissionRights = {
  Search: 'S',
  Add: 'I',
  Edit: 'M',
  Approve: 'A',
  Export: 'E',
  Delete: 'D',
};

export const PermissionCodes = {
  Dashboard: 'dashboard',
};

export default PermissionGuard;
