/* eslint-disable array-callback-return */
import { createSlice } from '@reduxjs/toolkit';
import { getVisibleColumns } from '../../../../services/ObjectConfiguration/ObjectConfiguration.service';
import { LoadingStatus, SortDirection } from '../../../../utils/constants';

const applySaveFilterHandler = (state, action) => {
  const fields = action.payload;

  if (!Array.isArray(fields) || fields.length === 0) {
    return;
  }
  const fieldObj = {};
  fields.forEach(({ Field, Value, Text }) => {
    fieldObj[Field] = Value;
    if (Text) {
      fieldObj[`${Field}_Text`] = Text;
    }
  });

  ['from', 'to', 'referenceNo', 'awbNO',
    'IMO',
    'VesselName',
    'ClientName'].forEach((fieldName) => {
    const fieldValue = fieldObj[fieldName];
    state.filter[fieldName] = fieldValue || '';
  });

  state.filter['statuses'] = fieldObj['statuses'] ?? [];
};

const initialFilterState = {
  from: '',
  to: '',
  referenceNo: '',
  awbNo: '',

  IMO: '',
  VesselName: '',
  ClientName: '',

  statuses: [],
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'EarliestPickupOn',
  sortDirection: SortDirection.Desc,
};

const orderDetailsNew = {
  DocketID: 0,
  DivisionID: 0,
  NoOfParcels: 0,
  EarliestPickupOn: '',
  LatestPickupOn: '',
  BunkerPort: '',
  LocationID: 0,
  StatusID: 0,
  LocationName: '',
  StatusName: '',
  SenderName: '',
  CompanyName: '',
  Phone: '',
  Email: '',
  Mobile: '',
  Fax: '',
  CollectionAddress1: '',
  CollectionAddress2: '',
  CollectionAddress3: '',
  CollectionSuburb: '',
  CollectionState: '',
  CollectionCountryCode: '',
  CollectionPostCode: '',
  CollectionRefNo: '',
  AWBNo: '',
  Flags: 0,
  CreatedOn: '',
  CreatedBy: '',
  LastUpdatedOn: '',
  LastUpdatedBy: '',
  DocketAssets: [
    {
      IMO: '',
      VesselName: '',
      IsValidated: true,
    },
  ],
  DocketTypes: [
    {
      DocketToDocketTypeID: 0,
      ListEntryDocketTypeID: 0,
      ListEntryDocketTypeName: '',
    },
  ],
};

const orderDetails = {
  senderDetails: {
    SenderName: '',
    CompanyName: '',
    Phone: '',
    Email: '',
  },
  senderAddress: {
    FirstLine: '',
    SecondLine: '',
    ThirdLine: '',
    TownCity: '',
    State: '',
    City: '',
    Country: {},
    PostCode: '',
    AddressList: [],
  },
  documentDetails: {
    referenceNo: '',
    document: [],
  },
  orderStatus: {
    status: '',
    date: '',
    message: '',
  },
};

const parcelDetails = {
  Type: {},
  NumberOfParcel: '',
  IMODetails: [{ IMO: '', VesselName: '', Active: false, NotFound: false }],
  ParcelTypes: [],
  DocketTypes: [],
};

const labDetails = {
  verifuelLab: null,
};

const pickUpDateTime = {
  EarliestDateTime: '',
  LatestDateTime: '',
  BunkerPort: '',
};

const initialState = {
  filter: initialFilterState,
  tableInfo: initialPageState,
  orders: [],
  totalOrderCount: 0,
  orderFilterData: [],
  totalFilterCount: 0,
  loadingStatus: LoadingStatus.Idle, // idle, pending, success, failed
  page: 1,
  sort: null,
  isFilterVisible: true,
  visibleGridColumns: [],
  mergedColumns: [],
  loadingGridColumnStatus: LoadingStatus.Idle,
  isSavedFilterLoaded: false,
  savedFilterList: [],
  loadingFilterSettingtatus: LoadingStatus.Idle,
  userOrderDeatils: orderDetails,
  userOrderDeatilsNew: orderDetailsNew,

  // - New approach by moving those object outside the parent object "orderDetails"
  userPickUpDateTime: pickUpDateTime,
  userLabDetails: labDetails,
  userParcelDetails: parcelDetails,

  onTableMode: false,
  isImoValid: true,
  isLoading: false,
  isReviewVisited: false,
};

const forwardOrderSlice = createSlice({
  name: 'forwardOrder',
  initialState,
  reducers: {
    reset(state) {
      const slice = { ...state };
      slice.userParcelDetails = initialState.userParcelDetails;
      return slice;
    },

    setUserData(state, action) {
      state.userOrderDeatilsNew = action.payload;
    },

    setUserDataNew(state, action) {
      state.userOrderDeatils = action.payload;
    },

    setUserParcelDetailsData(state, action) {
      state.userParcelDetails = action.payload;
    },

    changeLoadingState(state, action) {
      state.isLoading = action.payload;
    },

    checkImoValidation(state, action) {
      state.isImoValid = action.payload;
    },

    showHideFilter(state, action) {
      state.isFilterVisible = action.payload;
    },

    updateFilterValue(state, action) {
      state.filter[action.payload.propName] = action.payload.propValue;
    },

    orderTrackingFilter(state) {
      state.filter = { ...initialFilterState };
    },

    resetSearchFilter(state, action) {
      state.filter = { ...initialFilterState, ...action.payload };
      state.tableInfo = { ...initialPageState };
    },

    updatePageInfoField(state, action) {
      const { propName, propValue } = action.payload;
      state.tableInfo[propName] = propValue;
    },

    setPage(state, action) {
      state.tableInfo.page = action.payload;
    },

    updateLoadingStatus(state, action) {
      state.loadingStatus = action.payload;
    },

    searchOrder(state, action) {
      state.orders = action.payload.forwardSearch;
      state.totalOrderCount = action.payload.totalOrderCount;
    },

    orderFilter(state, action) {
      state.orderFilterData = action.payload.ordersFilter;
      state.totalFilterCount = action.payload.totalFilterCount;
    },

    updateFilterShowHideSettings(state, action) {
      state.orderFilterData = action.payload;
    },

    // Start - Grid Columns Setting Actions
    updateUserColumnSettings(state, action) {
      state.mergedColumns = action.payload;
      state.visibleGridColumns = getVisibleColumns(action.payload);
    },

    updateLoadingColumnSettingStatus(state, action) {
      state.loadingGridColumnStatus = action.payload;
    },

    updateVisibleGridColumns(state, action) {
      state.visibleGridColumns = action.payload;
    },
    updateMergedGridColumns(state, action) {
      state.mergedColumns = action.payload;
    },
    // End - Grid Columns Setting

    updateSavedFilterList(state, action) {
      state.isSavedFilterLoaded = true;
      state.savedFilterList = action.payload;
    },

    applySavedFilter(state, action) {
      applySaveFilterHandler(state, action);
    },

    updateLoadingFilterSettingStatus(state, action) {
      state.loadingFilterSettingtatus = action.payload;
    },

    updateUserSenderDetails(state, action) {
      state.userOrderDeatils.senderDetails[action.payload.propName] = action.payload.propValue;
    },

    updateUserSenderAddress(state, action) {
      state.userOrderDeatils.senderAddress[action.payload.propName] = action.payload.propValue;
    },

    updateUserPickUpDateTime(state, action) {
      state.userPickUpDateTime[action.payload.propName] = action.payload.propValue;
    },

    updateUserParcelDetails(state, action) {
      state.userParcelDetails[action.payload.propName] = action.payload.propValue;
    },

    updateUserParcelDetailsType(state, action) {
      state.userParcelDetails.Type[action.payload.propName] = action.payload.propValue;

      state.userParcelDetails.ParcelTypes.map((val) => {
        if (val.Text === action.payload.propName) {
          val['Checked'] = action.payload.propValue;
        }
      });
    },
    updateUserRequiredParcelDetailsType(state, action) {
      state.userParcelDetails[action.payload.propName] = action.payload.propValue;
    },

    updateUserParcelDetailsIMODetails(state, action) {
      const { propName, propValue, index } = action.payload;
      state.userParcelDetails.IMODetails[index][propName] = propValue;
    },

    addMoreIMODetails(state, action) {
      state.userParcelDetails.IMODetails.push(action.payload);
    },

    removeIMODetails(state, action) {
      const { index } = action.payload;
      state.userParcelDetails.IMODetails.splice(index, 1);
    },

    updateVerifuelLabDetails(state, action) {
      state.userLabDetails[action.payload.propName] = action.payload.propValue;
    },

    getUserPickupDcuments(state) {
      const awbRandomNumber = Math.floor(Math.random() * 80000000);
      const referenceRandomNumber = Math.floor(Math.random() * 80000000);
      state.userOrderDeatils.documentDetails.document[0].documentName = awbRandomNumber;
      state.userOrderDeatils.documentDetails.referenceNo = referenceRandomNumber;
    },

    updateSearchResult(state, action) {
      const { orders, totalOrderCount } = action.payload;

      state.orders = orders;
      state.totalOrderCount = totalOrderCount;
    },

    updateIsSearching(state, action) {
      state.isSearching = !!action.payload;
    },

    updateTableMode(state, action) {
      state.onTableMode = action.payload;
    },
    isReviewVisited(state, action) {
      state.isReviewVisited = action.payload;
    },
    getUserType(state, action) {
      const { type, userDetails, userAddresses } = action.payload;
      if (type === 'guest') {
        state.userOrderDeatils.senderDetails = { userType: type, ...state.userOrderDeatils.senderDetails };
      } else {
        state.userOrderDeatils.senderDetails = { userType: type, ...userDetails };
        state.userOrderDeatils.senderAddress.AddressList = userAddresses;
      }
    },
  },
});

export const filterFields = {
  from: 'from',
  to: 'to',
  referenceNo: 'referenceNo',
  awbNo: 'awbNo',
  status: 'statuses',
  IMO: 'IMO',
  VesselName: 'VesselName',
  ClientName: 'ClientName',
};

export const tableInfoFields = {
  page: 'page',
  pageSize: 'pageSize',
  sortColumn: 'sortColumn',
  sortDirection: 'sortDirection',
};

export const forwardOrderActions = forwardOrderSlice.actions;
export default forwardOrderSlice.reducer;
