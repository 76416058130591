import { combineReducers } from '@reduxjs/toolkit';
import authRedcer from '../slices/authSlice';
import userReducer from '../slices/userSlice';
import orderTrackingReducer from '../../pages/OrderTracking/store/slices/orderTrakingSlice';
import userRegistrationReducer from '../../pages/UserRegistration/store/slice/userRegistrationSlice';
import dashboardReducer from '../../pages/Dashboard/store/slice/dashboardSlice';
import forwardOrderReducer from '../../pages/ForwardOrder/store/slices/forwardOrderSlice';
import userProfileReducer from '../../pages/UserProfile/store/slice/userProfileSlice';
import pickUpRequestReducer from '../../pages/PickupRequest/store/slices/pickUpRequestSlice';

const rootReducer = combineReducers({
  auth: authRedcer,
  user: userReducer,
  orderTracking: orderTrackingReducer,
  userRegistration: userRegistrationReducer,
  dashboard: dashboardReducer,
  forwardOrder: forwardOrderReducer,
  userProfile: userProfileReducer,
  pickUpRequest: pickUpRequestReducer
});

export default rootReducer;
