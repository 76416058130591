import { createSlice } from '@reduxjs/toolkit';

const details = {
  location: null,
  title: { id: 'Mr', text: 'Mr' },
  firstName: '',
  lastName: '',
  company: '',
  contacts: [
    {
      type: { id: 0, text: 'Phone' },
      Phone: '',
      Email: '',
      IsDefault: true,
      Fax: ''
    },
  ],
};

const address = [
  {
    AddressLine1: '',
    AddressLine2: '',
    AddressLine3: '',
    City: '',
    State: '',
    CountryObj: null,
    PostCode: '',
    Country: '',
    IsDefault: true,
    AddressName: ''
  }
];

const registerDetails = {
  emailId: '',
  password: '',
  confirmPassword: '',
};

const agreement = {
  termCondition: false,
  privacyCookies: false,
};

const initialState = {
  userDetails: details,
  userAddress: address,
  userRegisterDetails: registerDetails,
  userAgreement: agreement,
  isImoValid: true
};

const userProfile = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    updateUserProfile(state, action) {
      const { profileDetails, addresses } = action.payload;
      state.userDetails = { ...profileDetails };
      state.userAddress = addresses;
    },
    updateUserDetails(state, action) {
      state.userDetails[action.payload.propName] = action.payload.propValue;
    },

    updateUserContactDetails(state, action) {
      const { propName, propValue, index } = action.payload;
      state.userDetails.contacts[index][propName] = propValue;
    },

    autoUpdateDefaultValue(state) {
      const prevPhoneActive = state.userDetails.contacts.indexOf(
        state.userDetails.contacts.find(
          (contactData) => contactData.type.text === 'Phone' && contactData.IsDefault === true
        )
      );

      const prevEmailActive = state.userDetails.contacts.indexOf(
        state.userDetails.contacts.find(
          (contactData) => contactData.type.text === 'Email' && contactData.IsDefault === true
        )
      );

      if (prevPhoneActive === -1) {
        const index = state.userDetails.contacts.findIndex(
          (contactData) => contactData.type.text === 'Phone' && contactData.IsDefault === false
        );
        if (index !== -1) {
          for (let i = 0; i < state.userDetails.contacts.length; i++) {
            if (state.userDetails.contacts[i].type.text === 'Phone') {
              state.userDetails.contacts[i].IsDefault = false;
            }
          }
          if (state.userDetails.contacts[index].type.text === 'Phone') {
            state.userDetails.contacts[index].IsDefault = true;
          }
        }
      }

      if (prevEmailActive === -1) {
        const index = state.userDetails.contacts.findIndex(
          (contactData) => contactData.type.text === 'Email' && contactData.IsDefault === false
        );
        if (index !== -1) {
          for (let i = 0; i < state.userDetails.contacts.length; i++) {
            if (state.userDetails.contacts[i].type.text === 'Email') {
              state.userDetails.contacts[i].IsDefault = false;
            }
          }
          state.userDetails.contacts[index].IsDefault = true;
        }
      }
    },

    autoUpdateDuplicateDefaultValue(state) {
      const phoneIndex = state.userDetails.contacts.findIndex(
        (contactData) => contactData.type.text === 'Phone' && contactData.IsDefault === true
      );
      const emailIndex = state.userDetails.contacts.findIndex(
        (contactData) => contactData.type.text === 'Email' && contactData.IsDefault === true
      );
      let duplicateDefaulPhone = 0;
      let duplicateDefaulEmail = 0;
      if (phoneIndex !== -1 || emailIndex !== -1) {
        state.userDetails.contacts.forEach((contactData) => {
          if (contactData.type.text === 'Phone' && contactData.IsDefault === true) {
            duplicateDefaulPhone += 1;
          }
          if (contactData.type.text === 'Email' && contactData.IsDefault === true) {
            duplicateDefaulEmail += 1;
          }
        });

        if (duplicateDefaulPhone > 1) {
          state.userDetails.contacts.forEach((contactData) => {
            if (contactData.type.text === 'Phone') contactData.IsDefault = false;
          });
          state.userDetails.contacts[phoneIndex].IsDefault = true;
        }

        if (duplicateDefaulEmail > 1) {
          state.userDetails.contacts.forEach((contactData) => {
            if (contactData.type.text === 'Email') contactData.IsDefault = false;
          });
          state.userDetails.contacts[emailIndex].IsDefault = true;
        }
      }
    },

    updateDefaultValue(state, action) {
      const { index, type } = action.payload;
      if (type === 'Phone') {
        for (let i = 0; i < state.userDetails.contacts.length; i++) {
          if (state.userDetails.contacts[i].type.text === 'Phone') {
            state.userDetails.contacts[i].IsDefault = false;
          }
        }
        state.userDetails.contacts[index].IsDefault = true;
      }

      if (type === 'Email') {
        for (let i = 0; i < state.userDetails.contacts.length; i++) {
          if (state.userDetails.contacts[i].type.text === 'Email') {
            state.userDetails.contacts[i].IsDefault = false;
          }
        }
        state.userDetails.contacts[index].IsDefault = true;
      }
    },

    updateUserAddressDetails(state, action) {
      const { propName, propValue, index } = action.payload;
      state.userAddress[index][propName] = propValue;
      if (propName === 'CountryObj') {
        state.userAddress[index]['Country'] = propValue.text;
      }
    },

    updateDefaultAddress(state, action) {
      const { index } = action.payload;
      const prevActive = state.userAddress.indexOf(
        state.userAddress.find(
          (addressData) => addressData.IsDefault === true
        )
      );
      if (prevActive !== -1) {
        state.userAddress[prevActive].IsDefault = false;
      }
      state.userAddress[index].IsDefault = true;
    },

    addMoreContactDetails(state, action) {
      state.userDetails.contacts.push(action.payload);
    },

    removeContactDetails(state, action) {
      const { index } = action.payload;
      state.userDetails.contacts.splice(index, 1);
    },

    addMoreAddress(state, action) {
      state.userAddress.push(action.payload);
    },

    removeAddress(state, action) {
      const { index } = action.payload;
      if (state.userAddress[index].IsDefault === true) {
        const firstAddress = state.userAddress.findIndex((addressData) => addressData.IsDefault === false);
        if (firstAddress !== -1) {
          state.userAddress[firstAddress].IsDefault = true;
        }
      }
      state.userAddress.splice(index, 1);
    },

    updateUserRegisterDetails(state, action) {
      state.userRegisterDetails[action.payload.propName] = action.payload.propValue;
    },

    updateUserAgreement(state, action) {
      state.userAgreement[action.payload.propName] = action.payload.propValue;
    },
    checkImoValidation(state, action) {
      state.isImoValid = action.payload;
    },
    reset: () => initialState,
  },
});

export const userProfileActions = userProfile.actions;
export default userProfile.reducer;
