import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
// import DashboardNavbar from './navigation/';
// import Sidebar from './navigation/Sidebar';
import Navbar from './navigation/NavBar';
import LegalBar from './LegalBar';

const LayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const LayoutWrapper = styled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '64px',
  // [theme.breakpoints.up("lg")]: {
  //   paddingLeft: "280px",
  // },
}));

const LayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const LayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
});

const MainLayout = () => {
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const layoutContentRef = useRef(null);

  useEffect(() => {
    if (layoutContentRef.current) {
      layoutContentRef.current.scrollTop = 0;
    }
  }, [location.pathname]);

  const toggleSideBarHandler = () => {
    setIsSidebarOpen((prepValue) => !prepValue);
  };

  return (
    <LayoutRoot>
      <Navbar onSidebarMobileOpen={toggleSideBarHandler} open={isSidebarOpen} />
      {/* <Sidebar onSidebarClose={() => setIsSidebarOpen(false)} isSidebarOpen={isSidebarOpen} /> */}

      {/* sx={{ paddingLeft: paddingLeft }} */}

      <LayoutWrapper>
        <LayoutContainer>
          <LayoutContent ref={layoutContentRef}>
            <Outlet />

            <LegalBar linkColor="text.primary" hideBottomBorder />
          </LayoutContent>
        </LayoutContainer>
      </LayoutWrapper>
    </LayoutRoot>
  );
};

export default MainLayout;
