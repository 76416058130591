/* eslint-disable function-paren-newline */
import { lazy } from 'react';
import MainLayout from './components/layouts/MainLayout';
import Loadable from './components/ui/Loadable';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/Guards/AuthGuard/AuthGuard';
import PermissionGuard, {
  PermissionCodes,
  PermissionRights as Rights,
} from './components/Guards/PermissionGuard/PermissionGuard';

import CustomPermissionGuard, {
  CustomFunctions,
} from './components/Guards/CustomPermissionGuard/CustomPermissionGuard';

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/auth/Login')));
const ForgotPassword = Loadable(lazy(() => import('./pages/auth/components/ForgotPassword/ForgotPassword')));
const Dashboard = Loadable(lazy(() => import('./pages/Dashboard/Dashboard')));
const ClientPassword = Loadable(lazy(() => import('./pages/UserRegistration/ClientPassword')));

const LandingPage = Loadable(
  lazy(() => import('./pages/LandingPage/LandingPage'))
);

const PickupRequest = Loadable(
  lazy(() => import('./pages/PickupRequest/PickupRequest'))
);
const PickupRequestReview = Loadable(
  lazy(() =>
    import('./pages/PickupRequest/PickupRequestReview/PickupRequestReview')
  )
);
const PickupRequestCompletion = Loadable(
  lazy(() =>
    import(
      './pages/PickupRequest/PickupRequestCompletion/PickupRequestCompletion'
    )
  )
);

const OrderTracking = Loadable(
  lazy(() => import('./pages/OrderTracking/OrderTracking'))
);
const OrderTrackingRequestDetails = Loadable(
  lazy(() =>
    import(
      './pages/OrderTracking/OrderTrackigRequestDetails/OrderTrackingRequestDetails'
    )
  )
);

const UserRegistration = Loadable(
  lazy(() => import('./pages/UserRegistration/UserRegistration'))
);

const ForwardOrder = Loadable(
  lazy(() => import('./pages/ForwardOrder/ForwardOrder'))
);
const ForwardOrderCopyRequest = Loadable(
  lazy(() =>
    import(
      './pages/ForwardOrder/ForwardOrderCopyRequest/ForwardOrderCopyRequest'
    )
  )
);
const ForwardOrderCopyRequestReview = Loadable(
  lazy(() =>
    import(
      './pages/ForwardOrder/ForwardOrderCopyRequest/CopyRequestReview/CopyRequestReview'
    )
  )
);
const ForwardOrderCopyRequestCompletion = Loadable(
  lazy(() =>
    import(
      './pages/ForwardOrder/ForwardOrderCopyRequest/CopyRequestCompletion/CopyRequestCompletion'
    )
  )
);

const UserProfile = Loadable(
  lazy(() => import('./pages/UserProfile/UserProfile'))
);
// Experiment Feautures
const ExperimentFeature = Loadable(
  lazy(() => import('./pages/ExperimentFeature/ExperimentFeature'))
);

// Error pages
const AuthorizationError = Loadable(
  lazy(() => import('./pages/error/AuthorizationError'))
);

const NotFound = Loadable(lazy(() => import('./pages/error/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/error/ServerError')));

const routes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard
            permission={PermissionCodes.Dashboard}
            right={Rights.Search}
          >
            <Dashboard />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'landing',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <LandingPage />,
      },
    ],
  },

  {
    path: ':userName/pickupRequest',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <PickupRequest />,
      },
      {
        path: 'review',
        element: <PickupRequestReview />,
      },
      {
        path: ':docketID/completion',
        element: <PickupRequestCompletion />,
      },
    ],
  },

  {
    path: 'orderTracking',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <OrderTracking />,
      },
      {
        path: ':docketID/requestDetail',
        element: <OrderTrackingRequestDetails />,
      },
    ],
  },

  {
    path: 'userRegistration',
    element: <UserRegistration />,
  },

  {
    path: 'forwardOrder',
    element: (
      <AuthGuard>
        <CustomPermissionGuard customFunction={CustomFunctions.Forwardorder}>
          <MainLayout />
        </CustomPermissionGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <ForwardOrder />,
      },
      {
        path: ':docketID/requestDetail',
        element: <OrderTrackingRequestDetails />,
      },
      {
        path: ':docketID/copyRequest',
        element: <ForwardOrderCopyRequest />,
      },
      {
        path: ':docketID/copyRequest/review',
        element: <ForwardOrderCopyRequestReview />,
      },
      {
        path: 'copyRequest/:docketID/completion',
        element: <ForwardOrderCopyRequestCompletion />,
      },
    ],
  },

  {
    path: 'profile',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <UserProfile />,
      },
    ],
  },

  {
    path: 'experiment',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <ExperimentFeature />,
      },
    ],
  },
  {
    path: '*',
    children: [
      {
        path: '',
        exact: true,
        element: <Navigate to="/landing" />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'ForgotPassword',
        element: <ForgotPassword />,
      },
      {
        path: 'changepassword/:tokenId',
        element: <ClientPassword />,
      },
      {
        path: '401',
        element: <AuthorizationError />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
