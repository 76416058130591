import { getAccessToken } from '../../utils/jwt';
import apiConfig from '../apiConfig';
import { getAxiosInstance } from '../../utils/axiosApi';

const axiosInstance = getAxiosInstance({
  baseUrl: apiConfig.AppSettingAPIUrl,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers = {
    Authorization: `Bearer ${getAccessToken()}`,
    // 'Accept': 'application/json',
  };

  return config;
});

const getObjectConfigurationsByMultiTypeAndBelongsToRecordids = async (idPairs) => {
  if (!Array.isArray(idPairs) || idPairs.length === 0) {
    return null;
  }

  try {
    const response = await axiosInstance.post('/AppSetting/objectconfigs/byPair', idPairs);

    return response.data;
  } catch (e) {
    throw new Error('Error search Object Configurations');
  }
};

const insertOrUpdateObjectConfiguration = async ({
  objectType,
  belongsTo,
  recordID,
  fields,
  updatedBy,
}) => {
  try {
    const response = await axiosInstance.post('/AppSetting/objectconfigs/save', {
      ObjectType: objectType,
      BelongsTo: belongsTo,
      RecordID: `${recordID}`,
      Config: JSON.stringify(fields),
      UpdatedBy: updatedBy,
    });

    return response.data;
  } catch (e) {
    throw new Error('Error insertOrUpdateObjectConfiguration');
  }
};

const AppSettingApi = {
  getObjectConfigurationsByMultiTypeAndBelongsToRecordids,
  insertOrUpdateObjectConfiguration,
};

export default AppSettingApi;
