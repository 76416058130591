import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar, Tooltip } from '@mui/material';
// import { AppBar, Box, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
// import MenuIcon from '../../../asset/icons/Menu';
// import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import AccountPopover from '../AccountPopover/AccountPopover';
// import ContentSearch from "./ContentSearch";
import ThemeIcon from '../ThemeIcon';
// import LanguagePopover from '../LanguagePopover';
// import NotificationsPopover from "./NotificationsPopover";
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useParams, useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import {
  PermissionCodes,
  PermissionRights,
} from '../../Guards/PermissionGuard/PermissionGuard';
import { useDispatch } from 'react-redux';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import { CustomFunctions } from '../../Guards/CustomPermissionGuard/CustomPermissionGuard';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { pickUpRequestActions } from '../../../pages/PickupRequest/store/slices/pickUpRequestSlice';

export const drawerWidth = 270;
export const navBarHeight = 64;

const NavbarRoot = styled(AppBar)(({ theme, open }) => ({
  ...(theme.palette.mode === 'light' && {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    // boxShadow: "none",
    // boxShadow: theme.shadows,
    boxShadow: 'rgba(37, 74, 135, 0.13) 0px 0px 28px 0px',

    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  marginLeft: 0,
  width: '100%',
  ...(open
    && {
      // marginLeft: drawerWidth,
      // width: `calc(100% - ${drawerWidth}px)`,
      // transition: theme.transitions.create(['width', 'margin'], {
      //   easing: theme.transitions.easing.sharp,
      //   duration: theme.transitions.duration.enteringScreen,
      // }),
    }),
  zIndex: theme.zIndex.drawer,
}));

const Navbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const navigate = useNavigate();

  const handleHomeNav = () => {
    navigate('/landing');
  };
  const handleDashboardNav = () => {
    navigate('/dashboard');
  };
  const { isAuthenticated, isAuthorized, hasCustomPermission } = useAuth();
  const { userName } = useParams();
  const dispatch = useDispatch();

  const hasDashpboarPermission = isAuthorized({
    permissionCode: PermissionCodes.Dashboard,
    right: PermissionRights.Search,
  });
  const hasForwardOrderPermission = hasCustomPermission({
    customFunction: CustomFunctions.Forwardorder,
  });

  const handleOrderTracking = () => {
    navigate('/orderTracking');
  };

  const handleForwardOrder = () => {
    navigate('/forwardOrder');
  };

  const handlePickup = () => {
    dispatch(pickUpRequestActions.isReviewVisited(false));
    navigate('/User/pickupRequest');
  };

  return (
    <NavbarRoot {...other}>
      <Toolbar sx={{ minHeight: navBarHeight }}>
        <Tooltip title="Home" placement="bottom">
          <IconButton color="primary" onClick={handleHomeNav}>
            <HomeIcon fontSize="medium" />
          </IconButton>
        </Tooltip>

        {hasDashpboarPermission && (
          <Tooltip title="Dashboard" placement="bottom">
            <IconButton color="primary" onClick={handleDashboardNav}>
              <DashboardIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        )}
        {userName !== 'guest' && (
          <>
            <Tooltip title="Pick up request" placement="bottom">
              <IconButton color="primary" onClick={handlePickup}>
                <LocalPostOfficeIcon
                  sx={{ fontSize: 30 }}
                  color="primary"
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Order Tracking" placement="bottom">
              <IconButton color="primary" onClick={handleOrderTracking}>
                <LocalShippingIcon sx={{ fontSize: 30 }} color="primary" />
              </IconButton>
            </Tooltip>
          </>
        )}

        {hasForwardOrderPermission && (
        <Tooltip title="ForwardOrder" placement="bottom">
          <IconButton color="primary" onClick={handleForwardOrder}>
            <ReplyAllIcon
              sx={{
                fontSize: 30,
                transform: 'scaleX(-1)',
              }}
              color="primary"
            />
          </IconButton>
        </Tooltip>)}

        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />

        {/* <LanguagePopover /> */}
        {/* <Box sx={{ ml: 1 }}>
          <ContentSearch />
        </Box> */}
        {/* <Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box> */}

        <Box sx={{ ml: 1 }}>
          <ThemeIcon />
        </Box>

        {isAuthenticated && (
          <Box sx={{ ml: 1 }}>
            <AccountPopover />
          </Box>
        )}
      </Toolbar>
    </NavbarRoot>
  );
};

Navbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default Navbar;
