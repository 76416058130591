import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import AuthorizationError from '../../../pages/error/AuthorizationError';

const CustomPermissionGuard = ({ customFunction, children }) => {
  const { hasCustomPermission } = useAuth();

  const isAllowed = hasCustomPermission({ customFunction });
  if (!isAllowed) {
    return <AuthorizationError />;
  }

  return <>{children}</>;
};

CustomPermissionGuard.propTypes = {
  children: PropTypes.node,
};

export const CustomFunctions = {
  Forwardorder: 'forwardorder',
};

export default CustomPermissionGuard;
